import _ from 'lodash'
import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Table,
  Icon,
  Popup,
  Pagination,
  Grid
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { getCompareStyle, getDollarString, getPercentString } from '../Common/TableCellUtils'

export const DataTable = ({ data }) => {
  const [state, dispatch] = useReducer(dataTableReducer, {
    column: data.sortableDefault,
    sortedData: data.data,
    direction: data.sortableDefault !== undefined && 'descending',
    itemsPerPage: 20,
    page: 1,
    sortDirection: data?.sortDirection
  })
  const { column, sortedData, direction, itemsPerPage, page } = state

  // Update sortedData whenever data.data changes
  useEffect(() => {
    dispatch({ type: 'UPDATE_DATA', data: data.data, sortableDefault: data.sortableDefault, sortDirection: data?.sortDirection })
  }, [data.data])

  // const [itemsPerPage, setItemsPerPage] = React.useState(20); // Set the number of items you want per page
  // const [page, setPage] = React.useState(1); // Add state for the current page
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = data.pagination ? startIndex + itemsPerPage : sortedData.length;
  const itemsForPage = sortedData.slice(startIndex, endIndex);

  return (
    <>
      {   // If pagination is enabled and we have more than our smallest page size, show the pagination options
        data.pagination && sortedData.length > 20 &&
        (<Grid columns='equal'>
          <Grid.Row>
            <Grid.Column verticalAlign='top' textAlign='center'>
              <Grid.Row>Rows per Page:</Grid.Row>
              <div>
                <select
                  name="itemsPerPage"
                  onChange={e => {
                    dispatch({ type: 'CHANGE_PAGE_LIMIT', value: e.target.value })
                  }}
                  value={itemsPerPage}
                  style={{ padding: 10 }}
                >
                  {PAGINATION_OPTIONS.map(option => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </Grid.Column>
          </Grid.Row>
          { // If we have more than one page, show the pagination
            sortedData.length > itemsPerPage && (
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Pagination
                    size='mini'
                    defaultActivePage={1}
                    totalPages={Math.ceil(sortedData.length / itemsPerPage)}
                    activePage={page}
                    boundaryRange={0}
                    siblingRange={1}
                    onPageChange={(e, { activePage }) => {
                      dispatch({ type: 'CHANGE_PAGE', activePage })
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
        </Grid>)
      }
      <Table textAlign='center' unstackable celled sortable={data.sortable} size='small' style={{ fontSize: 12 }}>
        <Table.Header>
          <Table.Row>
            {
              data.headers.map((row, i) =>
                <Table.HeaderCell
                  key={row.name}
                  sorted={column === i ? direction : null}
                  onClick={() => {
                    if ((!data.sortableColumns || data.sortableColumns.includes(i)) && data.sortable)
                      dispatch({ type: 'CHANGE_SORT', column: i })
                  }}
                >
                  {row.element || row.name}
                  {row.popup &&
                    <Popup
                      on='click'
                      position='bottom left'
                      content={row.popup.content}
                      trigger={<Icon name={row.popup.icon} color={row.popup.color} />}
                    />
                  }
                  {data.sortable && column !== i && (!data.sortableColumns || data.sortableColumns.includes(i)) && (<Icon fitted name='sort' />)}
                </Table.HeaderCell>
              )
            }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {itemsForPage.map((row, rowindex) =>
            <Table.Row key={rowindex}>
              {
                row.map((cell, cellindex) => {
                  let value = getCellValue(cell);
                  let content = getContent(cell, value);
                  let style = getCellStyle(cell, value);

                  return (<Table.Cell key={`${rowindex}-${cellindex}`} style={style} onClick={cell.onClick} >
                    {content}
                    {cell.popup &&
                      <Popup
                        on='click'
                        position='bottom left'
                        content={cell.popup.content}
                        trigger={<Icon name={cell.popup.icon} color={cell.popup.color} />}
                      />
                    }
                  </Table.Cell>)
                })
              }
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  )
}

DataTable.propTypes = {
  data: PropTypes.shape({
    // Enables sorting.
    sortable: PropTypes.bool,
    // Optional. Set sortableColumns arr with the index of the columns you want to only sort
    sortableColumns: PropTypes.arrayOf(PropTypes.number),
    // Optional. Set sortableDefault to the index of the column you want to sort by default
    sortableDefault: PropTypes.number,
    // define sort direction
    sortDirection: PropTypes.string,

    // Enabled pagination
    pagination: PropTypes.bool,
    headers: PropTypes.arrayOf(PropTypes.shape({
      // Optional. If you want to use a custom/complex element for the header instead of a string
      element: PropTypes.element,
      name: PropTypes.string.isRequired,
      popup: PropTypes.shape({
        content: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
      }),
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
      // Hard coded strings to put around the value
      prefix: PropTypes.string,
      postfix: PropTypes.string,

      // Only one of these should be set
      stringValue: validateAtLeastOneProp,
      numValue: validateAtLeastOneProp,
      dollarValue: validateAtLeastOneProp,
      pctValue: validateAtLeastOneProp,

      // Optional. To apply order from any other field that is not displayed, ex: we show 7am but the order is by the 7 value
      orderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

      // How many decimal places to show for pctValue
      decimals: PropTypes.number,

      // Determines if the value should be colored green for positive or red for negative
      compareStyle: PropTypes.bool,
      // An alternative to compareStyle, this will compare the value to the compareValue and color it green or red
      compareValue: PropTypes.number,
      // If true, negative values are good/green and positive values are bad/red
      compareStyleInvert: PropTypes.bool,
      // Sometimes you just need to pass in a custom style
      customStyle: PropTypes.object,
      // Link object to create a Custom Cell Link
      link: PropTypes.shape({
        to: PropTypes.string.isRequired,
        params: PropTypes.objectOf(PropTypes.any),
      }),
      popup: PropTypes.shape({
        content: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
      }),
      onClick: PropTypes.func,
    })).isRequired).isRequired,
  }),
};

const EMPTY_CELL = '--'

function validateAtLeastOneProp(props, propName, componentName) {
  const propsArr = [props['stringValue'], props['numValue'], props['dollarValue'], props['pctValue']]

  if (propsArr.every(prop => prop === undefined)) {
    return new Error(
      `One of 'stringValue', 'numValue', 'dollarValue', or 'pctValue' must be provided to '${componentName}'`
    );
  }
}

const sort = (data, column) => {
  return _.sortBy(data, [(item) => {
    if (item[column].orderBy !== undefined) return item[column].orderBy || 0

    return getCellValue(item[column])
  }])
}

function dataTableReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          sortedData: state.sortedData.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }
      return {
        ...state,
        column: action.column,
        sortedData: sort(state.sortedData, action.column),
        direction: 'descending',
      }

    case 'UPDATE_DATA':
      const sortableData = action.sortableDefault === undefined ? action.data : sort(action.data, action.sortableDefault)
      return {
        ...state,
        sortedData: (action.sortDirection === 'descending' || action.sortDirection === undefined) ? sortableData : sortableData.reverse(),
        direction: action.sortableDefault !== undefined && 'descending',
        column: action.sortableDefault,
        page: 1,
      }

    case 'CHANGE_PAGE':
      return {
        ...state,
        page: action.activePage,
      }

    case 'CHANGE_PAGE_LIMIT':
      return {
        ...state,
        itemsPerPage: action.value,
        page: 1,
      }

    default:
      throw new Error();
  }
}

// return first not (null/undefined) value in left-to-right order
function getCellValue(cell) {
  const { stringValue, numValue, dollarValue, pctValue } = cell;
  return stringValue ?? numValue ?? dollarValue ?? pctValue;
}

// function to parse a formatted value into number value
function parseFormattedValue(value) {
  if (typeof value === 'string') {
    const charsToReplace = [',', '$', '%', '#'];
    const regex = new RegExp(`[${charsToReplace.join('')}]`, 'g');
    let cleanValue = value.replace(regex, '').trim();
    return parseFloat(cleanValue);
  }
  // is not a string, return as is it.
  return value;
}

function getContent(cell, value) {
  let content = value;

  if (cell.dollarValue !== undefined) {
    content = getDollarString(value, cell.decimals);
  } else if (cell.pctValue !== undefined) {
    content = getPercentString(value, cell.decimals, cell.decimals);
  } else if (cell.numValue !== undefined && cell.decimals !== undefined) {
    const formattedValue = isNaN(value) ? EMPTY_CELL : value.toFixed(cell.decimals)
    content = formattedValue;
  }

  if (cell.prefix && content !== EMPTY_CELL) {
    content = `${cell.prefix}${content}`;
  }
  if (cell.postfix && content !== EMPTY_CELL) {
    content = `${content}${cell.postfix}`;
  }

  if (cell.link) {
    let href = cell.link.to;
    if (cell.link.params) {
      const params = new URLSearchParams(cell.link.params).toString();
      href += `?${params}`;
    }
    content = href.startsWith('http')
      ? <a href={href} style={{ color: '#EE7125', textDecoration: 'underline' }}>{content}</a>
      : <Link to={href} style={{ color: '#EE7125', textDecoration: 'underline' }}>{content}</Link>;
  }

  return content;
}

function getCellStyle(cell, value) {
  if (cell.customStyle) return cell.customStyle;
  if (cell.compareStyle || cell.compareValue) {
    const comp = cell.compareValue === undefined
      ? parseFormattedValue(value)
      : value - cell.compareValue;
    return getCompareStyle(comp * (cell.compareStyleInvert ? -1 : 1));
  }
  return {};
}

const PAGINATION_OPTIONS = [
  { key: 20, text: '20', value: 20 },
  { key: 40, text: '40', value: 40 },
]
