import { useMemo } from "react";
import { useQuery } from "react-query";
import { useQueryParams } from "./useQueryParams";
import { getData } from "../service/DataService";

const METRICS_TO_CHECK = [
  '_ACT_HRS',
  '_PROJ_OT_HRS',
  '_VF',
  '_VS',
  '_VF_PCT',
  '_VS_PCT',
  '_VF_WEEKDAY',
  '_VF_WEEKEND',
  '_VF_PCT_WEEKDAY',
  '_VF_PCT_WEEKEND',
  '_VS_WEEKDAY',
  '_VS_WEEKEND',
  '_VS_PCT_WEEKDAY',
  '_VS_PCT_WEEKEND',
  '_FCST_HRS',
  '_SCH_HRS',
  '_ACT_HRS_WEEKDAY',
  '_ACT_HRS_WEEKEND',
  '_FCST_HRS_WEEKDAY',
  '_FCST_HRS_WEEKEND'
];

const CLASSIFICATION_ENUM = {
  Manager: 0,
  Core: 1,
  Operations: 2,
  Specialty: 3,
  'Tool/Pro': 4,
  Supervisor: 5,
  Other: 6,
  Total: 7
};

export const useLaborQuery = ({ fpToggle, timeframe }) => {
  const { rgnNbr } = useQueryParams();
  const { data, isError, isLoading } = useQuery(['queryLaborOverviewByRgn', { locNbr: rgnNbr, fpToggle }], getData);
  const dataLaborOverviewFiltered = useMemo(() => {
    if (isLoading) return [];
    const filterData = data.filter(row => row['METRIC_LEVEL'] === 'RGN').filter(row => METRICS_TO_CHECK.some(key => row[timeframe + key] !== 0));
    return filterData.sort((left, right) =>
      CLASSIFICATION_ENUM[left['CLASSIFICATION']] > CLASSIFICATION_ENUM[right['CLASSIFICATION']] ? 1 : -1
    );
  }, [data, timeframe, isLoading]);

  const districtLaborDetails = useMemo(() => {
    if (isLoading) return [];

    return data.filter(row => METRICS_TO_CHECK.some(key => row[timeframe + key] !== 0));
  }, [data, timeframe, isLoading]);

  return {
    data: dataLaborOverviewFiltered,
    isError,
    isLoading,
    districtLaborDetails,
    rgnNbr
  };
}
