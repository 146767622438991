import { useState } from 'react';
import { Header, Table } from 'semantic-ui-react';
import { TimeframeMenu } from '../Menus/TimeFrameMenu';
import { useRegionOverviewContext } from '../../hooks/useRegionOverviewContext';

export const withOverviewHeader = (WrappedComponent, name) => {
  return () => {
    const { timeframe, settimeframe, rgnNbr, unit, setUnit } = useRegionOverviewContext();
    const dataAsOf = new Date();
    const getMonth = dataAsOf.getMonth() + 1;

    return (
      <>
        <TimeframeMenu timeframe={timeframe} settimeframe={settimeframe} unit={unit} setUnit={setUnit} showUnits />
        <Header textAlign='center'>
          Overview {name} #{rgnNbr}
        </Header>

        {/* replace date with value pulled from API */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          Data as of {dataAsOf.getFullYear() + '-' + getMonth.toString().padStart(2, 0) + '-' + dataAsOf.getDate()}
        </div>
        <WrappedComponent />
      </>
    );
  };
};
