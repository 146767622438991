import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { UnitMenu } from '../../Common/UnitMenu';
import { DataTable } from '../../Common/DataTable';
import useQueryApi from '../../../hooks/useQueryApi';
import { Loader, Header, Grid } from 'semantic-ui-react';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { TimeframeDropdownMon } from '../../Common/TimeframeDropdownMon';

export const SmdRgnRsn = ({ fpToggle, timeframe, settimeframe }) => {
  const [unit, setUnit] = useState('NOM');
  const { rgnNbr = 0, mkdnRsnCd = 0 } = useQueryParams();
  const { data, isError, isLoading } = useQueryApi('querySmdDstByRgnRsnCd', { locNbr: rgnNbr, mkdnRsnCd, fpToggle });

  if (isError) return <p>Something went wrong.</p>;
  if (isLoading) return <Loader active>Loading...</Loader>;

  const dataObj = {
    sortable: true,
    sortableDefault: 0,
    headers: [{ name: 'District #' }, { name: 'SMD ' }, { name: 'vLY ' }],
    data: data.map(row => [
      {
        stringValue: row['DST_NBR'],
        link: {
          to: '/district/smd/reason',
          params: {
            dstNbr: row['DST_NBR'],
            mkdnRsnCd: row['MKDN_RSN_CD']
          }
        }
      },
      unit === 'NOM'
        ? {
            dollarValue: row[timeframe + '_SMD'],
            decimals: 2
          }
        : {
            pctValue: row[timeframe + '_SMD_PCT'],
            decimals: 1
          },
      unit === 'NOM'
        ? {
            dollarValue: row[timeframe + '_SMD_VLY_DOL'],
            compareStyle: true,
            compareStyleInvert: true
          }
        : {
            numValue: row[timeframe + '_SMD_VLY_BPS'],
            decimals: 0,
            postfix: ' bps',
            compareStyleInvert: true,
            compareStyle: true
          }
    ])
  };

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/region/smd?rgnNbr=${rgnNbr}`} data-test='b2o-link'>
                Back to SMD Detail
              </Link>
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>Units:</Grid.Row>
            <Grid.Row>
              <UnitMenu unit={unit} setUnit={setUnit} />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ? (
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} />
              ) : (
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
              )}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header textAlign='center'>
        {data[0]
          ? data[0]['MKDN_RSN_CD'] + '-' + data[0]['MKDN_RSN_DESC']
          : `Reason Code ${mkdnRsnCd} - No markdowns found.`}
      </Header>
      {data[0] && <DataTable data={dataObj} />}
    </>
  );
};
