import { Header } from 'semantic-ui-react';
import { DataTable } from '../../Common/DataTable';

import { getCalloutStyle, getThousandDollarString } from '../../Common/TableCellUtils';

export const WTDSalesByDepartment = ({ data, level, locNbr }) => {
  const wtdSalesDeptDataObj = {
    data: data.map(row => [
      {
        link: {
          params: {
            [LEVELS_VARS[level]]: locNbr,
            deptNbr: row['DEPT_NM']
          },
          to: `/${level}/daily/dept`
        },
        stringValue: row['DEPT_NM']
      },
      {
        customStyle: getCalloutStyle(row['WTD_SLS_AMT']),
        stringValue: getThousandDollarString(row['WTD_SLS_AMT'], 1)
      },
      {
        customStyle: getCalloutStyle(row['DOL_TO_WTD_PLN']),
        stringValue: getThousandDollarString(row['DOL_TO_WTD_PLN'], 1)
      },
      {
        customStyle: getCalloutStyle(row['PCT_TO_WTD_PLN']),
        decimals: 1,
        pctValue: row['PCT_TO_WTD_PLN']
      },
      {
        customStyle: getCalloutStyle(row['WTD_COMP_PCT']),
        decimals: 1,
        pctValue: row['WTD_COMP_PCT']
      },
      {
        customStyle: getCalloutStyle(row['WTD_PLANNED_COMP_PCT']),
        decimals: 1,
        pctValue: row['WTD_PLANNED_COMP_PCT']
      }
    ]),
    headers: [
      { name: 'Dept' },
      {
        element: (
          <span>
            WTD Sales <br />$ (k)
          </span>
        ),
        name: 'wtd-sales-k'
      },
      {
        element: (
          <span>
            $ vPlan
            <br />
            WTD (k)
          </span>
        ),
        name: 'vplan-wtd-k'
      },
      {
        element: (
          <span>
            % to
            <br />
            WTD Plan
          </span>
        ),
        name: 'pct-wtd-plan'
      },
      {
        element: (
          <span>
            WTD
            <br />
            Comp %
          </span>
        ),
        name: 'wtd-comp'
      },
      {
        element: (
          <span>
            WTD Planned
            <br />
            Comp %
          </span>
        ),
        name: 'wtd-planned-comp'
      }
    ],
    sortable: true,
    sortableDefault: 0,
    sortableColumns: [0]
  };

  return (
    <>
      <Header textAlign='left'>WTD Sales by Department</Header>

      <DataTable data={wtdSalesDeptDataObj} />
    </>
  );
};

const LEVELS_VARS = {
  district: 'dstNbr',
  region: 'rgnNbr',
  store: 'strNbr'
};
