import React, { useState } from 'react';
import { ActiveEvents } from './ActiveEvents';
import useQueryApi from '../../hooks/useQueryApi';
import { Header, Loader } from 'semantic-ui-react';
import { CompletedEvents } from './CompletedEvents';
import { capitalizeFirstLetter } from '../../utils/string';
import { useQueryParams } from '../../hooks/useQueryParams';

export const Events = ({ fpToggle, level }) => {
  const { strNbr = 0, dstNbr = 0, rgnNbr = 0 } = useQueryParams();

  const locNbr = strNbr || dstNbr || rgnNbr;
  const endPoint = `query/eventsSummary/${level}`;
  const params = { locNbr, fpToggle };
  const { data, isLoading } = useQueryApi(endPoint, params);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (data.length === 0) {
    return <Header textAlign='center'>Invalid {level} number</Header>;
  }

  return (
    <>
      <Header textAlign='center'>
        Events - {capitalizeFirstLetter(level)} {locNbr}, {data[0]?.RGN_NM}
      </Header>

      <ActiveEvents events={data} level={level} locNbr={locNbr} />
      <CompletedEvents events={data} level={level} locNbr={locNbr} />
    </>
  );
};
