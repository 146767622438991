import { Segment, Tab } from 'semantic-ui-react';

import LaborDetailContextProvider from '../../context/LaborDetailContext';
import { LaborDetailsRgnOverview } from './Region/LaborDetailsOverview';
import { LaborDistrictListTab } from './Region/LaborDistrictListTab';

export const LaborDetailRgn = ({ fpToggle, settimeframe, timeframe, userEmplCtgryNm, levelName }) => {
  const panes = [
    {
      menuItem: 'Region',
      render: () => <LaborDetailsRgnOverview />
    },
    {
      menuItem: 'District List',
      render: () => <LaborDistrictListTab />
    }
  ];

  return (
    <LaborDetailContextProvider
      fpToggle={fpToggle}
      settimeframe={settimeframe}
      timeframe={timeframe}
      userEmplCtgryNm={userEmplCtgryNm}
      levelName={levelName}
    >
      <Segment>
        <Tab panes={panes} />
      </Segment>
    </LaborDetailContextProvider>
  );
};
