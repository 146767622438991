import React, { useState } from 'react'
import { Header, Loader, Grid, Menu, Segment } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { getCompareStyle } from '../Common/TableCellUtils'
import { useQueryParams } from '../../hooks/useQueryParams'
import { DataView } from '../Common/DataView'

const METRIC_CONSTANTS = {
  district: {
    query: 'querySafetyIncOshaLtGlbiByDst',
    FILTER_BY_METRIC: 'STR',
    METRIC_LEVEL: 'DST',
    urlParam: 'dstNbr',
    urlLinkLevel: 'store',
    urlLinkParam: 'strNbr',
    propToRender: 'STR_NBR'
  },
  region: {
    query: 'querySafetyIncOshaLtGlbiByRgn',
    FILTER_BY_METRIC: 'DST',
    METRIC_LEVEL: 'RGN',
    urlParam: 'rgnNbr',
    urlLinkLevel: 'district',
    urlLinkParam: 'dstNbr',
    propToRender: 'DST_NBR'
  }
}

export const SafetyDetailDst = withRouter(({ timeframe, settimeframe, fpToggle, userEmplCtgryNm, level, levelName }) => {

  const { dstNbr = 0, rgnNbr = 0 } = useQueryParams()
  const locNbr = dstNbr || rgnNbr
  const incOshaLtGlbiQuery = useQuery([METRIC_CONSTANTS[level]?.query, { locNbr, fpToggle }], getData)

  const [incOshaLt, setIncOshaLt] = useState('_INCIDENT_')

  if (incOshaLtGlbiQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let incOshaLtGlbiData = incOshaLtGlbiQuery.data

  // Separate RGN level, DST level and STR level rows

  let getRecordsBySubLevel = incOshaLtGlbiData.filter(row => {
    return row && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['FILTER_BY_METRIC']
  })

  let getRecordsByLevel = incOshaLtGlbiData.filter(row => {
    return row && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL']
  })

  const daySafesData = [{
    ...getRecordsByLevel[0],
    [METRIC_CONSTANTS[level]['FILTER_BY_METRIC'] + '_NBR']: locNbr,
    DAYS_SAFE: getRecordsByLevel[0]?.DAYS_SAFE,
  }, ...getRecordsBySubLevel]

  const daysSafeDataObj = {
    headers: [
      { name: 'Org # ' },
      { name: 'Day Safe ' }
    ],
    sortable: true,
    sortableDefault: 0,
    data: daySafesData.map((row, index) => [
      {
        stringValue: METRIC_CONSTANTS[level][index === 0 ? 'METRIC_LEVEL' : 'FILTER_BY_METRIC'] + ' ' + row[METRIC_CONSTANTS[level]['propToRender']],
        link: index > 0 ? ({
          to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/safety`,
          params: {
            [METRIC_CONSTANTS[level]['urlLinkParam']]: String(row[METRIC_CONSTANTS[level]['propToRender']]).padStart(4, '0')
          },
        }) : undefined,
        orderBy: row[METRIC_CONSTANTS[level]['propToRender']]
      },
      {
        stringValue: row['DAYS_SAFE']
      }
    ])

  }

  const safetySummaryDataObj = {
    headers: [
      { name: 'Org # ' },
      { name: 'Actual ' },
      { name: 'vP ' },
      { name: 'vLY ' }
    ],
    sortable: true,
    sortableDefault: 0,
    data: daySafesData.map((row, index) => [
      {
        stringValue: METRIC_CONSTANTS[level][index === 0 ? 'METRIC_LEVEL' : 'FILTER_BY_METRIC'] + ' ' + row[METRIC_CONSTANTS[level]['propToRender']],
        link: index > 0 ? ({
          to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/safety`,
          params: {
            [METRIC_CONSTANTS[level]['urlLinkParam']]: String(row[METRIC_CONSTANTS[level]['propToRender']]).padStart(4, '0')
          },
        }) : undefined,
        orderBy: row[METRIC_CONSTANTS[level]['propToRender']]
      },
      {
        numValue: row[timeframe + incOshaLt + 'COUNT_WC_TOTAL']
      },
      {
        stringValue: incOshaLt === '_INCIDENT_' ? '-' : undefined,
        pctValue: incOshaLt === '_OSHA_' ?
          (row[timeframe + '_OSHA_COUNT_WC_TOTAL'] - row[timeframe + '_WC_COUNTS_PLAN']) / row[timeframe + '_WC_COUNTS_PLAN'] :
          (row[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'] - row[timeframe + '_LT_COUNTS_PLAN']) / row[timeframe + '_LT_COUNTS_PLAN'],
        customStyle: incOshaLt === '_OSHA_' ?
          getCompareStyle(row[timeframe + '_WC_COUNTS_PLAN'] - row[timeframe + '_OSHA_COUNT_WC_TOTAL']) :
          getCompareStyle(row[timeframe + '_LT_COUNTS_PLAN'] - row[timeframe + '_LOST_TIME_COUNT_WC_TOTAL'])
      },
      {
        pctValue: (row[timeframe + incOshaLt + 'COUNT_WC_TOTAL'] - row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL']) / row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'],
        customStyle: getCompareStyle(row[timeframe + '_LY' + incOshaLt + 'COUNT_WC_TOTAL'] - row[timeframe + incOshaLt + 'COUNT_WC_TOTAL'])
      }
    ])
  }

  const menuOptions = [{ name: 'INCIDENT', value: '_INCIDENT_' }, { name: 'OSHA', value: '_OSHA_' }, { name: 'LOST TIME', value: '_LOST_TIME_' }]

  return (
    (userEmplCtgryNm.toUpperCase().includes("SALAR") || userEmplCtgryNm.toUpperCase().includes("OFFICER") || userEmplCtgryNm.toUpperCase().includes("CORP_CNTRC")) ?
      <>
        <Grid>
          <Grid.Column width={8}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row>
              <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${METRIC_CONSTANTS[level]['urlParam']}=${locNbr}`}>Back to Overview</Link>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} />
        </Grid>
        <Header textAlign='center' style={{ textTransform: 'capitalize' }}>Safety - {level} - {locNbr}, {levelName}</Header>
        <div style={{ display: 'flex', justifyContent: 'center' }}>Data as of {getRecordsByLevel[0]?.TRNSFM_DT}</div>
        <Header textAlign='center'>Days Safe: {getRecordsByLevel[0]?.DAYS_SAFE}</Header>
        <Grid>
          <Grid.Column width={8} />
          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Row>
            <Grid.Row>&nbsp;</Grid.Row>
          </Grid.Column>
        </Grid>
        <Segment>
          <DataView testId={'daysSafe' + level}>
            <DataView.Title position='center'>Days Safe</DataView.Title>
            <DataView.DataTable data={daysSafeDataObj} />
          </DataView>
        </Segment>
        <Segment>
          <DataView testId={'safetySumary' + level} className={''}>
            <DataView.Title position='center'>Safety Summary</DataView.Title>
            <Menu fluid attached='top' widths={3} color='orange'>
              {menuOptions.map(({ name, value }) => (
                <Menu.Item
                  key={value}
                  name={value}
                  active={incOshaLt === value}
                  content={name}
                  onClick={() => {
                    setIncOshaLt(value)
                  }}
                />
              ))}
            </Menu>
            <DataView.DataTable data={safetySummaryDataObj} />
          </DataView>
        </Segment>
      </> :
      <>
        <Header textAlign='center'>User does not have permission to access Safety Detail page. Please return to Overview.</Header>
      </>

  )

})