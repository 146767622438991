import { Link } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { DataTable } from '../Common/DataTable';
import { Header, Menu } from 'semantic-ui-react';
import { UnitsSellMenu } from './ UnitsSellMenu';
import { getParamByLevel } from '../../utils/timeFrameUtils';

export const ActiveEvents = ({ events, level, locNbr }) => {
  const [salesOrUnits, setSalesOrUnits] = useState('Units #');
  const activeEvents = useMemo(() => events.filter(event => event && event['EVENT_STATUS'] === 'ACTIVE'), [events]);
  const param = getParamByLevel(level);

  const activeEventsObj = useMemo(
    () => ({
      headers: [
        { name: 'Events' },
        { name: 'Begin Date' },
        { name: 'End Date' },
        { name: 'Total Sales' },
        { name: salesOrUnits === 'Sales $' ? 'Sell Thru %' : 'Unit Sell Thru %' }
      ],
      data: activeEvents.map(event => [
        {
          stringValue: (
            <Link
              to={`/${level}/events/detail?${param}=${locNbr}&evntId=${event['EVNT_ID']}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              {event['EVNT_NM']}
            </Link>
          )
        },
        { stringValue: event['EVNT_EFF_BGN_DT'] },
        { stringValue: event['EVNT_EFF_END_DT'] },
        { dollarValue: event['TOT_EVNT_SLS_AMT'] },
        {
          pctValue:
            salesOrUnits === 'Sales $'
              ? event['TOT_ST_NUM'] / event['TOT_ST_DEN']
              : event['TOT_ST_NUM_UNITS'] / event['TOT_ST_DEN_UNITS']
        }
      ]),
      sortable: true,
      pagination: true,
      sortableDefault: 1,
      sortDirection: 'ascending'
    }),
    [activeEvents, salesOrUnits, level, locNbr, param]
  );

  return (
    <>
      <Header textAlign='center'>Active Events</Header>
      <Menu compact size='small' style={{ marginBottom: '1rem' }}>
        <UnitsSellMenu salesOrUnits={salesOrUnits} setSalesOrUnits={setSalesOrUnits} />
      </Menu>
      <DataTable data={activeEventsObj} />
    </>
  );
};
