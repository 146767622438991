import React from 'react';
import { Tab } from 'semantic-ui-react';
import { CoreEvents } from './CoreEvents';
import { TotalEvents } from './TotalEvents';
import { NonCoreEvents } from './NonCoreEvents';

export const DistrictListTab = ({ events }) => {
  let filteredEvents = events.filter(row => row && row['METRIC_LEVEL'] === 'DST');

  return (
    <Tab.Pane>
      <TotalEvents events={filteredEvents} />
      <CoreEvents events={filteredEvents} />
      <NonCoreEvents events={filteredEvents} />
    </Tab.Pane>
  );
};
