import React from 'react';
import { Link } from 'react-router-dom';
import { DataView } from '../../Common/DataView';
import useQueryApi from '../../../hooks/useQueryApi';
import { Loader, Header, Grid } from 'semantic-ui-react';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { TimeframeDropdown } from '../../Common/TimeframeDropdown';
import { getThousandDollarString } from '../../Common/TableCellUtils';

const calculateSmdVpBpsValue = (row, timeframe) => {
  return (
    10000 *
    (row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT'] -
      row[timeframe + '_MKDN_PLN_AMT'] / row[timeframe + '_SLS_PLN_AMT'])
  );
};

const calculateSmdVpValue = (row, timeframe) => {
  return (
    row[timeframe + '_SMD'] -
    (row[timeframe + '_MKDN_PLN_AMT'] * row['TY_' + timeframe + '_SLS_AMT']) / row[timeframe + '_SLS_PLN_AMT']
  );
};

export const SmdRgnDept = ({ timeframe, settimeframe, fpToggle }) => {
  const { rgnNbr = 0, deptNbr: deptNbrInt = 0 } = useQueryParams();

  const endPoint = 'querySmdDeptByRgn';
  const params = { locNbr: rgnNbr, fpToggle };
  const { data, isLoading, isError } = useQueryApi(endPoint, params);

  if (isLoading) return <Loader active>Loading...</Loader>;
  if (isError) return <p>Something went wrong.</p>;

  let filteredData = data.filter(row => {
    return row && row['DEPT_NBR'].toString() === deptNbrInt && row['METRIC_LEVEL'] === 'DST';
  });

  const dataObj = {
    headers: [
      { name: 'District # ' },
      { name: 'SMD $ ' },
      { name: 'SMD % ' },
      { name: 'SMD bps vP ' },
      { name: 'SMD $ Over Under ' }
    ],
    data: filteredData.map(row => {
      const smdVpBpsValue = calculateSmdVpBpsValue(row, timeframe);
      const smdVpValue = calculateSmdVpValue(row, timeframe);
      return [
        {
          stringValue: row['DST_NBR'],
          link: {
            to: `/district/smd/dept`,
            params: {
              dstNbr: row['DST_NBR'],
              deptNbr: row['DEPT_NBR']
            }
          }
        },
        {
          stringValue: getThousandDollarString(row[timeframe + '_SMD'], 1)
        },
        {
          decimals: 2,
          pctValue: row[timeframe + '_SMD'] / row['TY_' + timeframe + '_SLS_AMT']
        },
        {
          decimals: 0,
          postfix: ' bps',
          compareStyle: true,
          numValue: smdVpBpsValue
        },
        {
          decimals: 1,
          compareStyle: true,
          numValue: smdVpValue
        }
      ];
    }),
    sortable: true,
    pagination: true,
    sortableDefault: 0
  };

  return (
    <>
      <Link style={{ color: '#EE7125' }} to={`/region/smd?rgnNbr=${rgnNbr}`} data-test='b2o-link'>
        Back to SMD Overview
      </Link>

      <Header textAlign='left'>
        SMD - Departments - Region {rgnNbr}, {filteredData[0]['RGN_NM']}
        <Header.Subheader>Data as of {filteredData[0]['TRNSFM_DT']}</Header.Subheader>
      </Header>

      <DataView>
        <DataView.Controls>
          Timeframe:
          <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
        </DataView.Controls>
        <DataView.Title>{filteredData[0]['DEPT_NBR'] + '-' + filteredData[0]['DEPT_NM']}</DataView.Title>
        <DataView.DataTable data={dataObj} />
      </DataView>
    </>
  );
};
