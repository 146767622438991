import {
  Header
} from 'semantic-ui-react'
import { getCompareStyle } from '../../Common/TableCellUtils'
import { useRegionOverviewContext } from '../../../hooks/useRegionOverviewContext'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { DataTable } from '../../Common/DataTable'
import { VOC_LTPA_GOAL, VOC_GET_GOAL, VOC_LTSA_GOAL } from '../../../constants/goals';

export const Overview = () => {
  const { rgnNbr } = useQueryParams() 
  const { timeframe, userEmplCtgryNm, unit, swmRegionData, SafetyIncOshaLtGlbiByRgnData } = useRegionOverviewContext()

  const isNOM = unit === 'NOM'
  const isPCT = unit === 'PCT'

  if(!swmRegionData[0]) return <Header textAlign='center'>Invalid or Unauthorized Region</Header>

  const regionMetrics = swmRegionData[0]
  const daysSafe = SafetyIncOshaLtGlbiByRgnData[0]
  
const data = [[
  {stringValue:  'Days Safe', link:SafetyIncOshaLtGlbiByRgnData.length > 0 && (userEmplCtgryNm.toUpperCase().includes("SALAR") || userEmplCtgryNm.toUpperCase().includes("OFFICER") || userEmplCtgryNm.toUpperCase().includes("CORP_CNTRC")) ? { to: `/region/safety`, params: {rgnNbr} }: undefined},
  {stringValue: SafetyIncOshaLtGlbiByRgnData.length > 0 ? daysSafe.DAYS_SAFE : '-'},
  {stringValue: '-'},
  {stringValue: '-'}
],[
  {stringValue: 'Sales'},
  {dollarValue: regionMetrics[timeframe + '_SLS_AMT_ACT']},
  {compareStyle: true, dollarValue: isNOM ? regionMetrics[timeframe + '_SLS_VP'] : undefined, pctValue: isPCT ? regionMetrics[timeframe + '_SLS_AMT_VP_PCT'] : undefined},
  {compareStyle: true, dollarValue: isNOM ? regionMetrics[timeframe + '_SLS_AMT_VLY'] : undefined, pctValue: isPCT ? regionMetrics[timeframe + '_SLS_AMT_VLY_PCT'] : undefined, decimals: 3}
],[
  {stringValue: 'Units'},
  {stringValue: regionMetrics[timeframe + '_UNTS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })},
  {stringValue: '-'},
  {compareStyle: true, stringValue: isNOM ? regionMetrics[timeframe + '_UNTS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) : undefined, pctValue: isPCT ? regionMetrics[timeframe + '_UNTS_VLY_PCT'] : undefined}
],[
  {stringValue: 'Transactions'},
  {stringValue: regionMetrics[timeframe + '_TRNS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })},
  {stringValue: '-'},
  {compareStyle: true, stringValue: isNOM ? regionMetrics[timeframe + '_TRNS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) : undefined, pctValue: isPCT ? regionMetrics[timeframe + '_TRNS_VLY_PCT'] : undefined}
],[
  {stringValue: 'Average Ticket'},
  {dollarValue: regionMetrics[timeframe + '_AVG_TCKT_ACT'], decimals: 2},
  {stringValue: '-'},
  {compareStyle: true, dollarValue: isNOM ? regionMetrics[timeframe + '_AVG_TCKT_VLY'] : undefined, pctValue: isPCT ? regionMetrics[timeframe + '_AVG_TCKT_VLY_PCT'] : undefined, decimals: 3}
],[
  {stringValue: 'Units per Basket'},
  {stringValue: (regionMetrics['TY_' + timeframe + '_UNT_SLS'] / regionMetrics['TY_' + timeframe + '_TXN_CNT']).toLocaleString(undefined, { maximumFractionDigits: 2 })},
  {stringValue: '-'},
  {compareStyle: true, stringValue: isNOM ? (regionMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] - regionMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['LY_' + timeframe + '_COMP_TXN_CNT']).toLocaleString(undefined, { maximumFractionDigits: 3 }) : undefined, pctValue: isPCT ? (regionMetrics['TY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['TY_' + timeframe + '_COMP_TXN_CNT'] - regionMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['LY_' + timeframe + '_COMP_TXN_CNT']) / (regionMetrics['LY_' + timeframe + '_COMP_UNT_SLS'] / regionMetrics['LY_' + timeframe + '_COMP_TXN_CNT']) : undefined, decimals: 2}
],[
  {stringValue: 'On-Shelf Availability', link:{ to: `/osa`, params: {rgnNbr} }},
  {stringValue: '-'},
  {stringValue: '-'},
  {stringValue: '-'}
],[
  {stringValue: 'SMD', link:{ to: `/region/smd`, params: {rgnNbr} }},
  {pctValue: regionMetrics[timeframe + '_SMD_ACT'], decimals: 2},
  {compareStyle: true, stringValue: isNOM ? regionMetrics[timeframe + '_SMD_VP'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' : '-', customStyle: getCompareStyle(-1 * regionMetrics[timeframe + '_SMD_VP']) },
  {compareStyle: true, stringValue: isNOM ? regionMetrics[timeframe + '_SMD_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps' : '-', customStyle: getCompareStyle(-1 * regionMetrics[timeframe + '_SMD_VLY'])}
],[
  {stringValue: 'Labor', link:{ to: `/region/labor`, params: {rgnNbr} }},
  {stringValue: regionMetrics[timeframe + '_LBR_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'},
  {stringValue: isNOM ? regionMetrics[timeframe + '_LBR_VF'].toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs' : undefined, pctValue: isPCT ? regionMetrics[timeframe + '_LBR_VF_PCT'] : undefined, customStyle: { color: regionMetrics[timeframe + '_LBR_VF_PCT'] > 0.01 || regionMetrics[timeframe + '_LBR_VF_PCT'] < -0.01 ? 'red' : 'green' }},
  {stringValue: '-'}
],[
  {stringValue: 'GET', link:{ to: `/region/ltsa`, params: {rgnNbr} }},
  {pctValue: regionMetrics[timeframe + '_GET_ACT'], decimals: 2, customStyle: { color: regionMetrics[timeframe + '_GET_ACT'] >= VOC_GET_GOAL ? 'green' : 'red' }},
  {stringValue: '-'},
  {pctValue: isNOM ? regionMetrics[timeframe + '_GET_VLY'] : '-', compareStyle: true}
],[
  {stringValue: 'LTSA', link:{ to: `/region/ltsa`, params: {rgnNbr} }},
  {pctValue: regionMetrics[timeframe + '_LTSA_ACT'], decimals: 2, customStyle: { color: regionMetrics[timeframe + '_LTSA_ACT'] >= VOC_LTSA_GOAL ? 'green' : 'red' }},
  {stringValue: '-'},
  {pctValue: isNOM ? regionMetrics[timeframe + '_LTSA_VLY'] : '-', compareStyle: true}
],[
  {stringValue: 'LTPA', link:{ to: `/region/voc`, params: {rgnNbr} }},
  {pctValue: regionMetrics[timeframe + '_ONLINE_SAT_ACT'] ? regionMetrics[timeframe + '_ONLINE_SAT_ACT'] : undefined, customStyle: {color: regionMetrics[timeframe + '_ONLINE_SAT_ACT'] > VOC_LTPA_GOAL ? 'green' : regionMetrics[timeframe + '_ONLINE_SAT_ACT'] < VOC_LTPA_GOAL ? 'red' : 'black'}},
  {stringValue: '-'},
  {stringValue: isNOM ? regionMetrics[timeframe + '_ONLINE_SAT_VLY'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '--', compareStyle: true, pctValue: isPCT ? '-' : undefined},
],[
  {stringValue: 'Leads', link:{ to: `/region/leads`, params: {rgnNbr} }},
  {stringValue: regionMetrics[timeframe + '_LEADS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })},
  {stringValue: '-'},
  {stringValue: isNOM ? regionMetrics[timeframe + '_LEADS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) : undefined, pctValue: isPCT ? regionMetrics[timeframe + '_LEADS_VLY_PCT'] : undefined, compareStyle: true}
],[
  {stringValue: 'Measures', link:{ to: `/region/meas`, params: {rgnNbr} }},
  {stringValue: regionMetrics[timeframe + '_MEAS_ACT'].toLocaleString(undefined, { maximumFractionDigits: 0 })},
  {stringValue: '-'},
  {stringValue: isNOM ? regionMetrics[timeframe + '_MEAS_VLY'].toLocaleString(undefined, { maximumFractionDigits: 0 }) : undefined, pctValue: isPCT ? regionMetrics[timeframe + '_MEAS_VLY_PCT'] : undefined, compareStyle: true}
],[
  {stringValue: 'Cashier Metrics', link:{ to: `https://pr-mstr-so.homedepot.com/MicroStrategy/servlet/mstrWeb?Server=MSTR-MYNUMBER-IS-20202-1&Project=PONE-SO&Port=0&evt=2048001&documentID=9C90664A48024856B23381BCFE86A029&&currentViewMedia=2&elementsPromptAnswers=E15D73A24D9970F1A0A15CBECADE46AE;E15D73A24D9970F1A0A15CBECADE46AE:${Number(rgnNbr)}` }},
  {stringValue: '-'},
  {stringValue: '-'},
  {stringValue: '-'}
],[
  {stringValue: 'HDPP - MA UA'},
  {pctValue: regionMetrics[timeframe + '_MA_HDPP']},
  {pctValue: regionMetrics[timeframe + '_MA_HDPP_VG'], compareStyle: true},
  {pctValue: regionMetrics[timeframe + '_MA_HDPP_VLY'], compareStyle: true}
],[
  {stringValue: 'HDPP - GM UA'},
  {pctValue: regionMetrics[timeframe + '_GM_HDPP']},
  {pctValue: regionMetrics[timeframe + '_GM_HDPP_VG'], compareStyle: true},
  {pctValue: regionMetrics[timeframe + '_GM_HDPP_VLY'], compareStyle: true}
]]

  const dataObj = {
    headers: [
      { name: '' },
      { name: 'Actual'},
      { name: `vP/vF ${isPCT ? '%' : '($)'}`},
      { name: `vLY ${isPCT ? '%' : '($)'}`},
    ], 
    data: data,
  }
   
  return (
    <>
      <DataTable data={dataObj} />
    </>
  )
}
