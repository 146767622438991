import { RegionTab } from './Region/RegionTab';
import useQueryApi from '../../hooks/useQueryApi';
import { Header, Loader, Tab } from 'semantic-ui-react';
import { DistrictListTab } from './Region/DistrictListTab';
import { useQueryParams } from '../../hooks/useQueryParams';

export const EventsDetailRgn = ({ fpToggle }) => {
  const { evntId = 0, rgnNbr = 0 } = useQueryParams();
  const { data, isLoading } = useQueryApi('queryEvntSumryByRgnEvnt', {
    evntId,
    locNbr: rgnNbr,
    fpToggle: fpToggle
  });
  const { data: eventDtlData, isLoading: isLoadingEventDtl } = useQueryApi('queryEvntDtlByRgnEvnt', {
    evntId,
    locNbr: rgnNbr,
    fpToggle
  });

  const { data: evntRgnStrSum, isLoading: isLoadingEvntRgnStrSum } = useQueryApi('queryEvntSmryByRgnEvnt', {
    locNbr: rgnNbr,
    evntId,
    fpToggle
  });

  if (isLoading || isLoadingEventDtl || isLoadingEvntRgnStrSum) return <Loader active>Loading...</Loader>;

  const eventSummaryData = data[0];
  if (!eventSummaryData) return <Header textAlign='center'>Invalid district number</Header>;

  const tabs = [
    {
      menuItem: 'Region',
      render: () => <RegionTab eventDtlData={eventDtlData} eventSummaryData={eventSummaryData} fpToggle={fpToggle} />
    },
    {
      menuItem: 'District List',
      render: () => <DistrictListTab events={evntRgnStrSum} />
    }
  ];

  return (
    <div>
      <Header>
        {eventSummaryData?.EVNT_NM} - Region {rgnNbr}, {eventSummaryData?.RGN_NM}
      </Header>

      <div className='d-flex mb-4 gap-3'>
        <span>Begin: {eventSummaryData['EVNT_EFF_BGN_DT']}</span>
        <span>End: {eventSummaryData['EVNT_EFF_END_DT']}</span>
      </div>

      <Tab className='mt-3' panes={tabs} />
    </div>
  );
};
