import { Header, Loader, Tab } from 'semantic-ui-react';
import { useRegionOverviewContext } from '../../../hooks/useRegionOverviewContext';
import { RegionOverviewTabs } from './RegionOverviewTabs';
import { withOverviewHeader } from '../../HOC/withHeader';

const RegionBody = withOverviewHeader(RegionOverviewTabs, 'Region');
const DistrictBody = withOverviewHeader(RegionOverviewTabs, 'District');

// TODO: Replace component with your needed imported componen e.g. <MyComponent />;
const tabsMenu = [
  {
    menuItem: 'Region',
    render: () => <RegionBody />
  },
  {
    menuItem: 'District List',
    render: () => <DistrictBody />
  }
];

export const RegionOverviewMenu = () => {
  const { isError, isRegionOverviewTabsLoading } = useRegionOverviewContext();
  if (isError) return <h1>Something went wrong</h1>;
  if (isRegionOverviewTabsLoading) return <Loader active>Loading</Loader>;
  if (false) {
    //TODO: replace value with correct validation field
    return <Header textAlign='center'>Invalid or Unauthorized District</Header>;
  }

  return <Tab panes={tabsMenu} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />;
};
